import React from 'react';
import CommonCardPage from '../../components/CommonCardPage/CommonCardPage';

const Error = () => {
    return (
        <CommonCardPage
            cardHeader={'ERROR'}
            height={'237px'}>
            <div className='common-card-page-content'>
                <p>Sorry, something went wrong or this page is unavailable.</p>
                <p>Please try again.</p>
            </div>
        </CommonCardPage>
    );
}

export default Error;
