import React from 'react';
import classes from './Footer.module.scss';
import { Link } from 'react-router-dom';
const Footer = () => {
    return (
        <footer className={classes.Footer}>
            <Link to="/terms">Sweepstakes Terms and Conditions</Link><span style={{whiteSpace: 'nowrap'}}>, and </span>
            <a href="https://screenvisionmedia.com/screenvision-privacy-policy/">Privacy Policy</a>
            <div>Powered by <a href="https://www.actv8me.com">ACTV8me</a></div>
        </footer>
    );
}

export default Footer;
