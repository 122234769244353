import React, { Fragment, useEffect, useState, useContext } from 'react';
import CommonCardPage from '../../components/CommonCardPage/CommonCardPage';
import Loader from '../../components/UI/Loader/Loader';
import DeviceContext from '../../store/device-context';
import axios from '../../utils/axiosInstance';

const UserSubScriptionPurge = (props) => {
    const [loading, setLoading] = useState(true);
    const [params, setParams] = useState(null);
    const [password, setPassword] = useState('');
    const { device } = useContext(DeviceContext);

    useEffect(() => {
        const paramsString = props.location.search;
        const paramsObj = new URLSearchParams(paramsString);
        const paramsToUpdate = {
            campaign_id: paramsObj.get('campaign_id'),
            trigger_id:  paramsObj.get('trigger_id'),
            episode_name: paramsObj.get('episode_name')
        }
        setParams(paramsToUpdate);
    }, [props.location.search]);

    useEffect(() => {
        if (params && params !== null) {
            setLoading(false)
        }
    }, [params]);

    const onPurgeHandler = async(e) => {
        e.preventDefault();
        try {
            const res = await axios.delete(
                `ybyl/campaign/${params.campaign_id}/trigger/${params.trigger_id}/user-subscriptions`,
                {data: {'password': password}},
                {headers: {'x-device-uuid': `${device.uuid}`}
            });
            if (res && (res.status === 204 || res.status === 200)) {
                handleSuccess();
            }
        } catch(err) {
            props.history.push('/error');
        }
    }

    const handleSuccess = () => {
        const message = `the user subscription data has been purged for episode ${params.episode_name}`
        props.history.push({
            pathname: '/completed',
            state: {
                title: 'Success',
                description: message,
                height: '237px',
                hasAction: false
            }
         });
    }

    return (
        <Fragment>
            { loading ? <Loader /> : (

                <CommonCardPage
                    cardHeader={'PURGE EPISODE DATA'}
                    height={'410px'}>
                        <div className="common-card-page-content">
                            <p>Episode <br /> <span style={{textDecoration: 'underline', color:'#CF1F29'}}>{params.episode_name}</span></p>
                            <p>Please enter the password below to authorize this action. Once the  data has been purged, there is no way to retrieve it. </p>
                            <form id="purge-form" onSubmit={onPurgeHandler} className="common-form-container">

                                <div className="common-input-container">
                                    <div>
                                        <label htmlFor="password">password</label>
                                    </div>
                                    <input
                                        required
                                        name="password"
                                        type="password"
                                        className="common-input"
                                        onChange={event => setPassword(event.target.value)} />
                                </div>

                                <button type="submit" className="btn-component panels flex-row" id='purge'>
                                    <div className={'btn-label'}>
                                        PURGE DATA FOR EPISODE
                                    </div>
                                    <div className="btn-inline"></div>
                                </button>
                            </form>
                        </div>
                </CommonCardPage>
            )}
        </Fragment>
    );
}

export default UserSubScriptionPurge;
