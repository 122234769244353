import React, { useEffect} from 'react';
import classes from './Video.module.scss';

const Video = props => {
    const { path, format, pauseAt, resumeAt, onStartedPlaying, onVideoPaused, onVideoResumed, onVideoCompleted} = props;
    const type  = "video/" + format;
    
    useEffect(() => {
        const videoplayer = document.getElementById("videoplayer");
        videoplayer.oncanplaythrough = function() {
            videoplayer.play();
            if(pauseAt && resumeAt) {
                setTimeout(function(){
                    videoplayer.pause();
                    onVideoPaused();
                    setTimeout(function(){
                      videoplayer.play();
                      onVideoResumed();
                    }, resumeAt, videoplayer);
                }, pauseAt, videoplayer);
            }

            onStartedPlaying();
        }

        videoplayer.onended = function() {
            onVideoCompleted();
        }
        
    });


    return (<div className={classes.HeroContainer}>
            <div className={classes.LogoImageContainer}>
                <video id="videoplayer" style={{width:"100%", height: '100%', borderRadius:"10px 10px 0px 0px"}} autoPlay playsInline muted>
                    <source src={path} type={type}/>
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>)

}


export default Video;
