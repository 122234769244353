import React, { useState } from 'react';

const DeviceContext = React.createContext({
    device: {
        id: '',
        uuid: '',
        latitude: '', 
        longitude: '',
        locale: '',
        manufacturer: '',
        model: '',
        os_name:'',
        os_version: '',
    },
    onSetDevice: () => {},
});

export const DeviceContextProvider = props => {
    const [device, setDevice] = useState({
        id: '',
        uuid: '',
        latitude: '', 
        longitude: '',
        locale: '',
        manufacturer: '',
        model: '',
        os_name:'',
        os_version: '',
    });

    const onSetDeviceHandler = (d) => {
        setDevice(d);
    }

    return (
        <DeviceContext.Provider value={{ device: device, onSetDevice: onSetDeviceHandler }}>
            {props.children}
        </DeviceContext.Provider>
    )
}

export default DeviceContext;