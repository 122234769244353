import React, { } from 'react';
import { CountdownCircleTimer } from 'react-countdown-circle-timer';
import classes from './CountDown.module.scss';

const renderTime = ({ remainingTime }) => {
    return (
      <div className="timer">
        <div className={classes.TimerValue}>{remainingTime}</div>
      </div>
    );
};

const placeholder = () => {
    return (
        <div className="timer">
            <div className={classes.TimerValue}>?</div>
        </div>
    );
}

const CountDown = props => {
    const { onComplete, duration, isPlaying} = props;

    return (
        <div
            className={classes.CountdownContainer}
            style={{marginTop: props.marginTop, marginBottom: props.marginBottom}}>
            <CountdownCircleTimer
                isPlaying={isPlaying}
                size={54}
                initialRemainingTime={duration ? duration : .75}
                strokeWidth={7}
                trailColor={'rgb(217,217,217,.5)'}
                onComplete={() => {
                    if (onComplete) {
                        onComplete();
                    } 
                return [true] // repeat animation in 1.5 seconds
                }}
                duration={duration ? duration : .75}
                colors="#ffffff">
                    { (duration && isPlaying) ? renderTime : placeholder}
            </CountdownCircleTimer>
        </div>
    );
}

export default CountDown;
