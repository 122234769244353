import React from 'react';
import CommonCardPage from '../../components/CommonCardPage/CommonCardPage';
const Retry = props => {
    return (
        <CommonCardPage
            type={'retry'}
            cardHeader={'Retry'}
            height={'237px'}
            hasAction={true}
            actionText={'Try Again'}
            onClick={props.onClick}>
            <div className='common-card-page-content'>
                <p>Sorry, something went wrong.</p>
                <p>Please try that again.</p>
            </div>
        </CommonCardPage>
    );
}

export default Retry;
