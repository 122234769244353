import React, {useState, useContext, useEffect, Fragment} from 'react';
import classes from './EmailSubmit.module.scss';
import { Link, withRouter } from 'react-router-dom';
import ResponseContext from '../../store/response-context';
import axios from '../../utils/axiosInstance';
import DeviceContext from '../../store/device-context';
import CommonCardPage from '../../components/CommonCardPage/CommonCardPage';
import Retry from '../Retry/Retry';

const EmailSubmit = props => {
    const { history } = props;
    const [enteredEmail, setEnteredEmail] = useState('');
    const [enteredFirstName, setEnteredFirstName] = useState('');
    const [enteredLastName, setEnteredLastName] = useState('');
    const [enteredPhoneNumber, setEnteredPhoneNumber] = useState('');
    const [formSubmit, setFormSubmit] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(null);
    const [alreadySubscribed, setAlreadySubscribed] = useState(null);
    const [showEmailError, setShowEmailError] = useState({
        value: false,
        retryFunc: null
    });
    const { response } = useContext(ResponseContext);
    const { device } = useContext(DeviceContext);

    useEffect(() => {
        console.log(response);
        if (response.winner === null) {
            // props.history.push({
            //     pathname: '/completed',
            //     state: {
            //         title: 'SCAN THE QR CODE',
            //         secondTitle: 'TO GUESS THE MOVIE',
            //         description: '',
            //         url: 'https://www.trailerpack.com',
            //         height: '220px',
            //         urlText: 'GO TO TRAILERPACK.COM',
            //         hasAction: true
            //     }
            // });
        }
    }, [response, props.history])

    useEffect(() => {
        const onPostEmailSubscription = async() => {
            try {
                const res = await axios.post(`user/subscribe`, {
                    source_type: 'question_answer',
                    source_id: response.responseId,
                    email: enteredEmail,
                    first_name: enteredFirstName,
                    last_name: enteredLastName,
                    phone_number: enteredPhoneNumber
                }, {
                    headers: {'x-device-uuid': `${device.uuid}`}
                });
                if (res) {
                    if (res.status === 201) {
                        setSubmitSuccess(true);
                    }
                    if (res.status === 200) {
                        setAlreadySubscribed(true);
                    }
                }
            } catch(err) {
                setShowEmailError({
                    value: true,
                    retryFunc: onPostEmailSubscription
                })
            }
        }
        if (formSubmit) {
            onPostEmailSubscription();
        }
    }, [formSubmit, enteredEmail, response, device, history, enteredFirstName, enteredLastName, enteredPhoneNumber, props.history])

    const emailSubmitHandler = (e) => {
        e.preventDefault();
        setFormSubmit(true);
    }

    const handleFoxNavigate = (e) => {
        e.preventDefault();
        window.open('https://www.trailerpack.com')
    }
    const handleRetry = () => {
        showEmailError.retryFunc.call();
        setShowEmailError({
            value: false,
            retryFunc: null
        });
    }
    const emailSubmitForm = (
        <form id="submit-form" className={classes.FormContainer} onSubmit={emailSubmitHandler}>
            <div className={classes.SweepstakesContainer}>
                {/* <input
                    required
                    className={classes.SweepstakesCheck}
                    type="checkbox"
                    id="sweepstakes-rules" /> */}

                <label htmlFor="sweepstakes-rules" className={classes.CheckboxLabel}>
                    By submitting your email, you agree to the <Link to="/terms">Sweepstakes Terms and Conditions</Link>, and <a href="https://screenvisionmedia.com/screenvision-privacy-policy/">Privacy Policy</a>
                </label>
            </div>
            <p style={{fontSize : "10px", width:"92%", textAlign: "left"}}>
                    <i>* Fandango Promotional Code (“Code”) is good towards the purchase of one movie ticket (up to $5 total ticket price and associated fees and charges) to see any movie at Fandango partner theaters in the US. Valid only for purchases at Fandango.com or via the Fandango app. Code is void if not redeemed by 10/4/24. Not for resale; void if sold or exchanged. Offer valid for one-time use only. Limit 1 Code per person. The redemption of the Code is subject to Fandango’s Terms and Policies at www.fandango.com/terms-and-policies. See <a href="https://games.trailerpack.com/terms">https://games.trailerpack.com/terms</a> for full details.</i>
                </p>

        </form>
    );
    
    const klaviyoForm = (
        <div className={"klaviyo-form-SYLQ5z"}></div>
    )

    const emailSubmitFormContainerWinner = (
            <CommonCardPage
                cardHeader={'Congrats!'}
                height={'640px'}
                hasAction={false}
                // actionText={'SUBMIT ENTRY'}
                // type={'submit'}
                >
                <div className={classes.FormSubmitDescription}>
                    <p>Congratulations! You got it right! Please provide your information below to enter for your chance to win a $5 Fandango Movie Ticket*!</p>
                    <p>If you are selected as a winner, we will contact you at the email provided.</p>                 
                </div>
                {klaviyoForm}
                {emailSubmitForm}                
            </CommonCardPage>
    );

    const emailSubmitFormContainerLoser = (
        <CommonCardPage
            cardHeader={'Bummer!'}
            height={'500px'}
            hasAction={false}
            // actionText={'SUBMIT ENTRY'}
            // type={'submit'}
            >
            <div className={classes.FormSubmitDescription} style={{height:'85px'}}>
                <p>Bummer you didn't answer correctly but you can still win a $5 Fandango Movie Ticket*!</p>
                <p>Please provide your information below.</p>
            </div>
            {klaviyoForm}
            {emailSubmitForm}
        </CommonCardPage>
);

    const submitSuccessMessage = (
        <CommonCardPage
            cardHeader={'ENTRY SUBMITTED'}
            height={'380px'}
            hasAction={true}
            actionText={'GO TO TRAILERPACK.COM'}
            url={'https://www.trailerpack.com'}>
            <div className={classes.ThankYou}>
            </div>
        </CommonCardPage>
    );

    const alreadyEntered = (
        <div className="common-container" style={{width: '100%'}}>
            {/* <Hero /> */}
            <div className={classes.SubmitContainer}>
                <div>
                    <div className={classes.AlreadyEnteredTitle}>
                        You have already <br/>
                        entered this contest
                    </div>
                    <div style={{marginTop: '58px', height: '104px'}} className={classes.AlreadyEnteredDescription}>
                        It looks like you’ve already <br/>
                        entered this contest previously. <br/>
                        Thank you!
                    </div>
                    <div className={classes.FoxButtonContainer}>
                        <button onClick={handleFoxNavigate} className={classes.FoxButton}>Trailerpack.com</button>
                    </div>
                </div>
            </div>

        </div>
    );

    return (
        <Fragment>
            { !formSubmit && !submitSuccess && (response.winner ? emailSubmitFormContainerWinner : emailSubmitFormContainerLoser)}
            { formSubmit && submitSuccess && submitSuccessMessage }
            { formSubmit && alreadySubscribed && alreadyEntered }
            { formSubmit && showEmailError.value === true && <Retry onClick={handleRetry}/> }

        </Fragment>
        
    );
}

export default withRouter(EmailSubmit);
