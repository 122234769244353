import React, { useState, useEffect } from 'react';
import IcCircle from '../../../images/ic-circle.svg'
import IcCheck from '../../../images/ic-checkmark.svg'
import IcClose from '../../../images/ic-close.svg';
import IcUnselect from '../../../images/ic-unselected.svg';
import IcCircleClose from '../../../images/ic-circleclose.svg'
import classes from './Button.module.scss';

const Button = props => {
    const { id, click, type, selectedAnswer, winner, quizTimeout, correctId, locked} = props;
    const [selectedIconImage, setSelectedIconImage] = useState(IcCircle);
    const [btnClasses, setBtnClasses] = useState(['btn-component', 'panels']);
    const [btnLabelClass, setBtnLabelClass] = useState(['btn-label']);
    const [btnIconClass, setBtnIconClass] = useState(['btn-icon']);   
    const [showOffsetBorder, setShowOffsetBorder] = useState(false); 
    const [showIcon, setShowIcon] = useState(false);

    useEffect(() => {
        if (type === 'quiz' && selectedAnswer && selectedAnswer === id) {
            setBtnClasses(['btn-component', 'btn-selected', 'panels'])
            setSelectedIconImage(IcCircleClose);
            setShowOffsetBorder(false);
        } else if (type === 'icon') {
            setBtnClasses(['btn-component', 'panels'])
            setSelectedIconImage(IcUnselect);
            setShowOffsetBorder(false);
        } else {
            if(locked) {
                setBtnClasses(['btn-component', 'panels', classes.BtnLocked]);    
            } else {
                setBtnClasses(['btn-component', 'panels']);    
            }
            setSelectedIconImage(IcCircle);
            setShowOffsetBorder(false);
        }
    }, [selectedAnswer, id, type, locked]);

    useEffect(() => {
        if (type === 'quiz' && quizTimeout && !locked) {
            setShowOffsetBorder(false);            
            if (winner) {
                if (selectedAnswer === id) {
                    setSelectedIconImage(IcCheck);
                    setBtnClasses(['btn-component', 'btn-correct', 'panels']);
                    setShowIcon(true)
                } else {
                    setSelectedIconImage(IcClose);
                    setBtnClasses(['btn-component', 'panels', classes.BtnLocked]);
                    setSelectedIconImage(IcCircle);
                    setShowOffsetBorder(false);
                }
            } else {
                if (selectedAnswer === id) {
                    setBtnClasses(['btn-component', 'btn-wrong', 'panels'])
                    setSelectedIconImage(IcClose);
                    setShowOffsetBorder(false);
                    setShowIcon(true)
                } else if (correctId === id) {
                    setSelectedIconImage(IcCheck);
                    setBtnClasses(['btn-component', 'btn-correct', 'panels']);
                    setShowIcon(true)

                } else {
                    setSelectedIconImage(IcClose);
                    setBtnClasses(['btn-component', 'panels', classes.BtnLocked]);
                    setSelectedIconImage(IcCircle);
                    setShowOffsetBorder(true);
                }
            }
        }
    }, [winner, quizTimeout, selectedAnswer, id, type, correctId, locked]);


    const onButtonMouseOver = (e) => {
        type === 'quiz' && !quizTimeout && setSelectedIconImage(IcCircleClose);
    }

    const onButtonMouseOut = (e) => {
        if (type === 'quiz' && selectedAnswer !== id && !quizTimeout) {
             setSelectedIconImage(IcCircle);
        }
    }

    const buttonClickHandler = (e) => {
        if (type === 'quiz') {
            if (!quizTimeout) {
                click(id);
            }
        } else {
            click();
        }
    }

    return (        
        <div className={btnClasses.join(' ')}
        style={{marginTop: props.marginTop}}
        onClick={buttonClickHandler}
        onMouseOver={onButtonMouseOver}
        onMouseOut={onButtonMouseOut}>
            
            <div className={btnLabelClass.join(' ')} style={(showIcon) ? { maxWidth: '184px', marginLeft: '40px'} : {maxWidth:'100%'}}>
                    {props.children}
            </div>
            {showIcon && 
            <div className={btnIconClass.join(' ')}>
                    <img src={selectedIconImage} alt={selectedIconImage}/>
                </div>
            }
            {/* {(showOffsetBorder ) ? <div className="btn-inline" style={{top: "-2px", left: "-2px", border: "1px solid #4c4c4c", borderRadius: "10px"}}></div> : ''} */}
    </div>
    );
}

export default Button;
