import React, { Fragment } from 'react';
import Title from '../Title/Title';

const CardHeader = props => {
    const { type, header, secondHeader } = props;
    return (
        <Fragment>
            <Title
                fontSize={'28px'}
                fontWeight={'600'}
                color={'#171C8F'}   
                width="100%"              
                >
                    {header}
            </Title>
            { type === 'common' && <Title width="100%" fontSize={'28px'} fontWeight={'600'} color={'#171C8F'}>{secondHeader}</Title> }
            <div
                style={{
                    margin: type === 'common' ? '20px 0' : '10px 0',
                    borderBottom: '1px solid #CCCCCC'}}>
            </div>
        </Fragment>
    );
}

export default CardHeader;
