import React, { Fragment } from 'react';
import classes from './CommonCardPage.module.scss';
import Logo from '../UI/Logo/Logo';
import Card from '../UI/Card/Card';
import CardHeader from '../UI/CardHeader/CardHeader';
import Button from '../UI/Button/Button';
import UnselectImage from '../../images/ic-unselected.svg';
import Footer from '../Footer/Footer';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

const CommonCardPage = props => {
    const { type, url, height, cardHeaderType, cardHeader, cardSecondHeader, hasAction, actionText, showHowToPlay} = props;    
    const onAction = () => {
        (type === 'landing' || type === 'retry' || type === 'url') ? props.onClick()
            : type === 'submit' ? props.onSubmit()
            : props.history.push({
                pathname: '/redirect',
                state: {
                    url: url,
                }
              });
    }

    return (
        <Fragment>

            <div className="common-container" style={{paddingBottom: type === 'submit' && '60px'}}>
                <Logo></Logo>
                <div className={classes.CommonCardPageContainer} style={{height: 'auto'}}>
                    <Card>
                        <div className={classes.CommonCardPageContent}>
                            <CardHeader type={cardHeaderType} header={cardHeader} secondHeader={cardSecondHeader} />
                            {props.children}
                        </div>

                    </Card>
                    
                    {   hasAction && type !== 'submit' ? (

                        <div className={classes.Test}>
                            <Button icon={UnselectImage} type={'icon'} click={onAction}>{actionText}</Button>
                        </div>
                        ) : type === 'submit' ? (

                            <div className={classes.Test}>
                                <button form="submit-form" type="submit" className={['btn-component', 'panels'].join(' ')}>
                                    <div className="btn-label">SUBMIT ENTRY</div>
                                    {/* <div className="btn-inline"></div> */}
                                </button>
                            </div>
                        ) : ''
                    }
                </div>
            
                {showHowToPlay && <div className={classes.HowToPlay}><Link to="/howtoplay">How To Play?</Link></div>} 

            { (
                <div className="footer-container">
                    <Footer></Footer>
                </div>
                )
            }
            </div>

        </Fragment>
    );
}

export default withRouter(CommonCardPage);
