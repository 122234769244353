import React, { useState } from 'react';

const ParamsContext = React.createContext({   
    params: {        
        triggerable_id: null,
        triggerable_type: null,
        client_id: null,
        tracking_enabled: true,
        view: null,
        shareable: false,
        device_uuid: null,
        guid: null,
        content_id: null,
        fallback: false,
        trigger_uuid: null
    },
    onSetParams: () => {},
});

export const ParamsContextProvider = props => {
    const [params, setParams] = useState({
        triggerable_id: null,
        triggerable_type: null,
        client_id: null,
        tracking_enabled: true,
        view: null,
        shareable: false,
        device_uuid: null,
        guid: null,
        content_id: null,
        fallback: false,
        trigger_uuid: null
      });

    const onSetParamsHandler = (p) => {
        setParams(p);
    }

    return (
        <ParamsContext.Provider value={{ params: params, onSetParams: onSetParamsHandler }}>
            {props.children}
        </ParamsContext.Provider>
    )
}

export default ParamsContext;