import axios from 'axios';

const instance = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? process.env.REACT_APP_ACTV8_API_ROOT_PROD : process.env.REACT_APP_ACTV8_API_ROOT_DEV,
    timeout: 15000,
    headers: {
        common: {
            'x-api-key': process.env.NODE_ENV === 'production' ? process.env.REACT_APP_ACTV8_API_KEY_PROD : process.env.REACT_APP_ACTV8_API_KEY_DEV,
            'x-api-version': process.env.NODE_ENV === 'production' ? process.env.REACT_APP_ACTV8_API_VERSION_PROD : process.env.REACT_APP_ACTV8_API_VERSION_DEV,
            'Content-Type': 'application/json',
        }
    }
});

instance.interceptors.request.use(request => {
    return request;
});

instance.interceptors.response.use(
    undefined,
    error => {
        console.error('ERROR: ', error)
        return Promise.reject(error);
    }
)

export default instance;