import React, { useState, useEffect, useContext, Fragment } from 'react';
import Loader from '../../components/UI/Loader/Loader';
import Card from '../../components/UI/Card/Card';
import classes from './Quiz.module.scss';
import Button from '../../components/UI/Button/Button';
import axios from '../../utils/axiosInstance';
import DeviceContext from '../../store/device-context';
import ParamsContext from '../../store/params-context';
import CountDown from '../../components/UI/CountDown/CountDown';
import ResponseContext from '../../store/response-context';
import CommonCardPage from '../../components/CommonCardPage/CommonCardPage';
import { withRouter } from 'react-router';
import Retry from '../Retry/Retry';
import Video from '../../components/UI/Video/Video';
import Logo from '../../components/UI/Logo/Logo';
import moment from 'moment';

const Quiz = props => {
    const now = moment.now()
    const [loading, setLoading] = useState(true);
    const [showQuiz, setShowQuiz] = useState(false);
    const [question, setQuestion] = useState('');
    const [answers, setAnswers] = useState([]);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [quizTimeout, setQuizTimeout] = useState(false);
    const [winner, setWinner] = useState(null);
    const [correctId, setCorrectId] = useState(null);
    const [categoryName, setCategoryName] = useState(null);
    const [loadingResults, setLoadingResults] = useState(null);
    const [content, setContent] = useState(null);
    const { device } = useContext(DeviceContext);
    const { params } = useContext(ParamsContext);
    const { onSetResponse, response } = useContext(ResponseContext);
    const [showRetry, setShowRetry] = useState({
        value: false,
        retryFunc: null
    });
    const [video, setVideo] = useState({
        path : null,
        format : null,
        pauseAt : 0,
        resumeAt : 0        
    });
    const [showAnswers, setShowAnswers] = useState(false);
    const [countDownIsPlaying, setCountDownIsPlaying] = useState(false);
    const [guessLocked, setGuessLocked] = useState(false);
    const [videoFinishedPlaying, setVideoFinishedPlaying] = useState(false);

    useEffect(() => {
        const onRedirectUrl = () => {
            if (content.type === 'url') {
                props.history.push({
                    pathname: '/completed',
                    state: {
                        title: content.display_name,
                        description: content.description,
                        url: content.url,
                        urlText: 'Trailerpack.com',
                        hasAction: true,
                        trackingEnabled: params.tracking_enabled,
                        type: 'url',
                        contentId: content.id,
                        uuid: device.uuid
                    }
                });
            }
        }

        const updateStatus = async status => {
            try {
                const uri = `/device/${device.uuid}/content/${content.id}?status=${status}`;
                const res = await axios.put(uri, {}, {
                    headers: {
                    'Content-Type': 'application/json',
                    'x-device-uuid': `${device.uuid}`
                    }
                });
                if (res && res.status === 200) {
                    if (res.data && res.data.message) {
                        console.log('STATUS: ', status + ' ', res.data.message);
                        return res.data;
                    }
                }
            } catch (err) {
                console.error('Error: ', err);
            }
        };
        if (content && content !== null && content.id  && ((answers.length > 0 && showQuiz) || (content.type === 'url'))) {
            if (content.type !== 'url' && params.tracking_enabled) {
                updateStatus(1);
            } else {
                onRedirectUrl();
            }
        }
    }, [content, answers, device.uuid, params.tracking_enabled, props.history, showQuiz])

    useEffect(() => {
        const checkSelectedAnswer = async() => {
            setLoadingResults(true)
            try {
                const res = await axios.post(`device/${device.uuid}/question/${content.id}/answer`, {'choice_id': selectedAnswer}, {
                    headers: {'x-device-uuid': `${device.uuid}`}
                });
                if (res && res.data) {
                    const winner = res.data.correct_choice_id === res.data.response.choice_id;
                    winner ? setWinner(true) : setWinner(false);
                    setCorrectId(res.data.correct_choice_id);
                    onSetResponse({responseId: res.data.response.id, winner: winner});
                    setLoadingResults(false)
                } else {
                    onSetResponse({responseId: '', winner: null});
                    setLoadingResults(false)
                }
            } catch(err) {
                setLoadingResults(false);
                if (err.message === 'timeout of 15000ms exceeded') {
                    setShowRetry({
                        value: true,
                        retryFunc: checkSelectedAnswer
                    });
                } else {
                    props.history.push('/error');
                }
            }
        }

        const onSetLoser = () => {
            setLoadingResults(true)
            setWinner(false);
            onSetResponse({responseId: '', winner: null});
            setLoadingResults(false)
        }

        if (quizTimeout) {
            selectedAnswer ? checkSelectedAnswer() : onSetLoser();
        }

        // eslint-disable-next-line
    }, [quizTimeout, selectedAnswer, device, content]);

    useEffect(() => {
        const getChoices = async(content) => {
            try {
                const res = await axios.get(`device/${device.uuid}/question/${content.id}/choices`, {
                    headers: {'x-device-uuid': `${device.uuid}`}
                });
                if (res && res.data) {
                    let qAns = res.data.map(el => {
                        return {
                            id: el.id,
                            text: el.text
                        }
                    });
                    setAnswers(qAns);
                }
            } catch(err) {
                setLoading(false);
                if (err.message === 'timeout of 15000ms exceeded') {
                    setShowRetry({
                        value: true,
                        retryFunc: getChoices
                    });
                } else {
                    props.history.push('/error');
                }
            }
        }

        const catchQuestion = async() => {
            setLoading(true)
            try {
                console.log("Catch Question!");
                let endpoint
                if(!params.trigger_uuid) {
                    endpoint = `qrcode/${params.triggerable_id}/catch?client_id=${params.client_id}`;
                } else {
                    endpoint = `content/deliver?trigger_uuid=${params.trigger_uuid}`;
                    params.trigger_uuid = null;
                }

                const res = await axios.post(endpoint, {}, {
                    headers: {'x-device-uuid': `${device.uuid}`}
                });
                if (res && res.data && res.data[0]) {
                    setContent(res.data[0])
                    if (res.data[0].type === 'question') {
                        if (res.data[0].categories[0]) {
                            setCategoryName(res.data[0].categories[0].name);
                        }
                        setQuestion(res.data[0].display_name);
                        if(res.data[0].linked_assets) {
                            res.data[0].linked_assets.forEach(asset => {
                                if(asset.component === 'video' && asset.sub_component === 'hero') {

                                    setVideo({
                                        path : asset.path,
                                        format : asset.format,
                                        pauseAt : ((asset.metadata.pause_at)?? 0),
                                        resumeAt : ((asset.metadata.resume_at)?? 0)
                                    });
                                }
                            });
                        }
                        getChoices(res.data[0]);
                        setLoading(false);
                    }
                } else {
                    props.history.push('/error');
                }
            } catch(err) {
                setLoading(false);
                if (err.message === 'timeout of 15000ms exceeded') {
                    setShowRetry({
                        value: true,
                        retryFunc: catchQuestion
                    });
                } else {
                    props.history.push('/error');
                }
              }
        }

        if (!params.trigger_uuid && (!params.triggerable_id || !params.triggerable_id === null || !params.client_id || params.client_id === null)) {
            setLoading(false);
            props.history.push({
                pathname: '/completed',
                state: {
                    title: 'SCAN THE QR CODE',
                    secondTitle: 'TO PLAY AGAIN',
                    description: '',
                    url: 'https://www.trailerpack.com',
                    height: '220px',
                    urlText: 'GO TO TRAILERPACK.COM',
                    hasAction: true
                }
            });
        } else {
            catchQuestion();
        }
    }, [params, device, props.history, response.quizAnswered]);

    const onSelectAnswer = (id) => {
        setSelectedAnswer(id)
    }

    const handleRetry = () => {
        showRetry.retryFunc.call();
        setShowRetry({
            value: false,
            retryFunc: null
        });
    }

    const onNavigateSubmit = () => {
        if (quizTimeout && winner !== null) {
            props.history.push('/submit');
        }
    }

    const startQuiz = () => {
        setShowQuiz(true);
    }

    const handleVideoStarted = () => {
        console.log('... Video is now playing');
        setCountDownIsPlaying(true);
    }

    const handleVideoPaused = () => {
        console.log('... Video is now paused');
        //setCountDownIsPlaying(true);
        setShowAnswers(true)
    }

    const handleVideoResumed = () => {
        console.log('... Video is now resumed');
        setGuessLocked(true)
    }

    const handleVideoCompleted = () => {
        console.log('... Video is completed');
        setVideoFinishedPlaying(true);
        setGuessLocked(false);
    }

    const parseDate = (datestring, format='YYYY-MM-DD HH:mm:ss') => {
        if(!datestring){
            console.log('Expiration not set');
            return;
        }
        const dateUTC = moment.utc(datestring).toDate();
        const dateLocal = moment(dateUTC).local().format(format)
        const convertedDateTs = Date.parse(dateLocal);
        return convertedDateTs;
    } 

    const loader = (
        <Fragment>
            <Logo></Logo>
            <Loader loadingMessage={'Preparing your quiz'} />
        </Fragment>
    );

    const quizCountdown = (
        <CountDown
            selectedAnswer={selectedAnswer}
            marginTop={'30px'}
            marginBottom={'0px'}
            // duration={(video.resumeAt)? video.resumeAt/1000 : 10}
            duration={15}
            //delay={(video.pauseAt)??false}
            delay={false}
            isPlaying={countDownIsPlaying}            
            onComplete={() => setQuizTimeout(true)} 
            />
    );

    const winnerHeader = (
        <Fragment>
            {
                winner !== null ? (
                    <div className={classes.WinnerHeader}>
                        <div className={classes.WinnerTitle}>
                            { guessLocked === true && 'GUESS LOCKED IN...'}
                            { (winner === true && !guessLocked) ? 'CORRECT!' : (winner === false && !guessLocked) ? 'WRONG!' : '' }
                        </div>
                        {/* <div className={classes.WinnerSubTitle}>
                            {(winner === true && !guessLocked) ? 'GREAT JOB' : (winner === false && !guessLocked) ? 'BUMMER!' : ''}
                            <div className={classes.SelectAnswer}>
                            {(winner === true && !guessLocked) && 'Please wait for the contest entry page.'}
                            </div>
                        </div> */}
                    </div>
                ) : <CountDown marginTop={'30px'} marginBottom={'22px'} />
            }
        </Fragment>
    );

    const letsPlay = (
        <CommonCardPage
            type={'landing'} 
            cardHeaderType={'common'}
            cardHeader={now > parseDate('2024-02-19 08:00:00') ? 'GUESS THE ACTOR' : 'GUESS THE MOVIE'}
            cardSecondHeader={''}
            hasAction={true}
            height={'230px'}
            actionText={"Let's Play"}
            onClick={() => startQuiz()}
            showHowToPlay={false}>
            <div className="common-card-page-content">
                <p><strong>Ready to play? Tap below to get started!</strong></p>
            </div>
        </CommonCardPage>
    )

    const quiz = (
        <div className="common-container" style={{position: 'relative'}}>            
            { !quizTimeout ? quizCountdown : winnerHeader }
            { !quizTimeout && <div className={classes.SelectAnswer}></div> }
            <div className={classes.QuizCardContainer}>
                <div
                    style={{
                            minHeight: '355px',
                            height: '100%',
                            width: '320px',
                            display:'flex',
                            flexDirection:'column',
                            justifyContent:'center',
                            alignItems:'center'}}>
                    <Card>
                        <div style={{height: '100%'}}>                            
                            <Video 
                                format={video.format} 
                                path={video.path} 
                                // pauseAt={video.pauseAt} 
                                // resumeAt={video.resumeAt} 
                                pauseAt={0} 
                                resumeAt={0}                                 
                                onStartedPlaying={handleVideoStarted} 
                                onVideoResumed={handleVideoResumed}
                                onVideoPaused={handleVideoPaused}
                                onVideoCompleted={handleVideoCompleted}
                                ></Video>
                            {/* <CardHeader header={categoryName ? categoryName : 'question'} />                             */}
                            <p className={classes.QuizQuestion} style={{color: "#171C8F", textShadow: "0.5px 0.5px 0 0 #EFAB16", fontSize: "20px", padding: "0px 15px 0px 15px", fontWeight:"600"}}>{question}</p>
                            {
                                !loadingResults && (
                                    <div style={{margin: '30px 25px 21px 25px'}}>
                                        {answers.map(answer => {
                                            return (
                                                <Button
                                                    locked={guessLocked}
                                                    correctId={correctId}
                                                    winner={winner}
                                                    quizTimeout={quizTimeout}
                                                    selectedAnswer={selectedAnswer}
                                                    marginTop={'6px'}
                                                    click={onSelectAnswer}
                                                    id={answer.id}
                                                    key={answer.id}                                                    
                                                    type={'quiz'}>
                                                        {answer.text}
                                                </Button>
                                            )
                                        })}
                                    </div>
                                )
                            }
                        </div>
                    </Card>
                </div>

            </div>
            {
                quizTimeout && !loadingResults && videoFinishedPlaying && (
                    <div style={{marginTop: '0px'}}>
                        <CountDown
                            marginTop={'10px'}
                            marginBottom={'10px'}
                            duration={5}
                            isPlaying={videoFinishedPlaying}
                            onComplete={() => onNavigateSubmit()} 
                            />
                    </div>
                )
            }
        </div>
    );

    return (
        <div className="common-container" style={{position: 'relative'}}>
            {            
                loading ? loader : showQuiz ? quiz : showRetry.value === true ? <Retry onClick={handleRetry} /> : letsPlay
            }
        </div>
    );
}

export default withRouter(Quiz);
