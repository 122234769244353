import React from 'react';
import Card from '../../components/UI/Card/Card';
import CardHeader from '../../components/UI/CardHeader/CardHeader';
import classes from './HowToPlay.module.scss';

const HowToPlay = () => {
return (
<div className="common-container">
    <div style={{marginTop: '1px', width: '100%'}}>
        <Card>
            <div className="common-card-content">
                <CardHeader type={'common'} header={'HOW TO PLAY'} secondHeader={''} />
            </div>
        </Card>
    </div>
</div>
);
}

export default HowToPlay;