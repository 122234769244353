import React, { useState } from 'react';

const ResponseContext = React.createContext({
    response: {
        responseId: '',
        winner: null
    },
    onSetResponse: () => {}
});

export const ResponseContextProvider = props => {
    const [response, setResponse] = useState({
        responseId: '',
        winner: null
    });
    
    const onSetResponseHandler = (r) => {
        setResponse(r);
    }
    return (
        <ResponseContext.Provider value={{ response: response, onSetResponse: onSetResponseHandler }}>
            {props.children}
        </ResponseContext.Provider>
    )
}

export default ResponseContext;